/* Hero Section */

.heroSection {
  height: calc(100vh - var(--nav-height));
  padding: var(--gutter);
  width: 100%;
  position: relative;
}

.heroBody {
  position: absolute;
  bottom: 5.5rem;
  left: var(--gutter);
  right: var(--gutter);
  line-height: 6rem;
}

.heroTitle {
  font-family: var(--heading-font);
  color: var(--primary-white);
  font-size: 7rem;
  font-weight: normal;
}

.subHeadHero {
  font-family: var(--heading-font);
  font-size: 5rem;
}

.heroText {
  font-family: var(--body-font);
  color: var(--primary-white);
  font-size: 2rem;
  font-weight: normal;
  margin-top: 2.5rem;
  line-height: 3rem;
}

.heroTextHighlight {
  color: var(--primary-blue);
}

.greenHighLight {
  color: var(--app-green);
}

/* Water Foot Print Section */

.footPrintSection {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--gutter);
}

.footPrintsImage {
  max-height: 20rem;
}

.leftFootContainer,
.rightFootContainer {
  width: 50%;
}

.rightFootContainer {
  display: flex;
  justify-content: flex-end;
}

.footCalculatorBody {
  font-size: 1.5rem;
  font-weight: 500;
  width: 75%;
  margin-bottom: 2rem;
}

.footCalculatorTitle {
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

/* Glass Half Full Section */

.glassHalfFullMainContainer {
  padding: 5rem 2rem;
}

.betterIndiaContainer {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.glassHalfFullSection {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.glassHalfFullHeader {
  font-weight: 500;
  color: var(--primary-blue);
  margin-bottom: 20px;
}

.glassHalfFullSubHeading {
  font-weight: 500;
  color: var(--primary-black);
  margin-bottom: 20px;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.mainContainerBetterIndia {
  margin-top: 2rem;
}

.imgBetterIndia {
  width: 100%;
}

.betterIndiaContent {
  margin-left: 3rem;
  font-weight: 500;
}

.contentBetterIndia {
  text-align: justify;
}

.linkToReadMoreBetterIndia {
  text-decoration: none;
  color: var(--app-green);
  padding-top: 2rem;
}

.hashTagBetterIndia {
  font-weight: 500;
  margin-top: 2rem;
  color: var(--app-green);
  font-size: 2rem;
}


/* Vision Mission Goal Section */

.visionMissionGoalSection {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-evenly;
  /* padding: var(--gutter); */
  flex-wrap: wrap;
}

/* We Home Section */

.weHomeContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* padding: var(--gutter); */
  padding: 5rem 2rem;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1200px) {
  .heroSection {
    height: calc(80vh - var(--nav-height));
  }

  .heroTitle{
    font-size: 5rem;
  }

  .subHeadHero {
    font-size: 4rem;
  }

  .heroText {
    font-size: 1.5rem;
    line-height: 1.6;
  }
}

@media only screen and (max-width: 600px) {
  .heroSection {
    height: calc(70vh - var(--nav-height));
  }

  .heroBody {
    bottom: 4rem;
  }

  .heroTitle{
    font-size: 3rem;
    line-height: 80%;
  }

  .subHeadHero {
    font-size: 2rem;
    line-height: 1.6;
  }

  .heroText {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .footPrintsImage {
    max-height: 10rem;
  }

  .footCalculatorBody {
    font-size: 1rem;
    width: 100%;
  }

  .footCalculatorTitle {
    font-size: 1.2rem;
  }

  .glassHalfFullMainContainer {
    padding: 1rem 0.3rem;
  }

  .imgContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .imgBetterIndia {
    width: 100%;
    margin-bottom: 1rem;
  }

  .betterIndiaContent {
    margin-left: 0rem;
  }

  .hashTagBetterIndia {
    font-size: 1.5rem;
  }  
}
