.container {
  padding: 3rem 2rem;
}

.followUs {
  color: var(--primary-blue);
  font-size: 1.2rem;
  font-weight: 500;
}

.iconComponent {
  display: flex;
  gap: 20px;
  font-size: 2.2rem;
  margin-top: 1rem;
}

.iconComponent a {
  color: var(--app-green);
  text-decoration: none;
}

.logo {
  width: 120px;
  cursor: pointer;
  margin-bottom: 1rem;
}

.button {
  background: linear-gradient(
    180deg,
    var(--app-green) 0%,
    var(--primary-blue) 100%
  );
  border: 0;
  border-radius: 5px;
  padding: 3px 10px;
  font-size: 1rem;
  margin-left: 10px;
  color: var(--primary-white);
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow 0.5s;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.input {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #333;
  outline: none;
}

.connectText {
  color: var(--app-green);
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin-top: 10px;
}

.connectWithUs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;
}

.complaintInfo {
  color: var(--primary-white);
  font-size: 0.8rem;
}

.copyright {
  color: var(--primary-white);
}

.termsDetails {
  margin-bottom: 0.8rem;
}

.termsDetails > a {
  color: var(--primary-white);
  font-size: 0.8rem;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .connectWithUs {
    align-items: flex-start;
    margin-bottom: 1rem;
  }
}
