/* Vision Mission Goal Card */

.visionMissionGoalParent {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 20rem;
  padding: 3rem 0rem;
}

.visionMissionGoalImage {
  margin-top: 10px;
  width: 100px;
  height: 100px;
  margin-bottom: 37px;
}

.visionMissionGoalHeader {
  font-weight: 500;
  color: var(--primary-white);
  text-transform: uppercase;
  margin-bottom: 4.2rem;
  font-size: 3rem;
}

.visionMissionGoalDescription {
  text-align: center;
  color: var(--primary-white);
}

/* GlassHalfFull Card */
.glassHalfFullParent {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 20rem;
  padding: 2.5rem 0rem;
}

.glassHalfFullImage {
  margin-top: 10px;
  width: 150px;
  height: 150px;
  margin-bottom: 37px;
}

.glassHalfFullHeader {
  font-weight: 500;
  color: var(--app-green);
  text-transform: uppercase;
  margin-bottom: 1.8rem;
  font-size: 3rem;
}

.glassHalfFullDescription {
  text-align: center;
  color: var(--primary-black);
}

/* We Home Section Card */
.weHome {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  align-items: center;
  /* height: 28rem; */
  width: 20rem;
  padding: 3.3rem 0rem;
  /* margin: 2rem 3rem; */
  margin-bottom: 2rem;
  /* margin-right: 1rem; */
  /* flex: 1 0 auto; */
}

.weHome div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.weHomeBlue {
  background-color: var(--primary-blue);
}

.weHomeDark {
  background-color: var(--primary-dark-shade);
}

.weHomeImage {
  margin-bottom: 2rem;
}

.weHomeImage img {
  height: 100px;
}

.weHomeTitle h3 {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.weHomeTitleLight {
  color: var(--primary-white);
}

.weHomeTitleDark {
  color: var(--primary-dark-shade);
}

.weHomeDescription p {
  font-size: 1rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: 500;
}

.weHomeDescriptionBlue {
  color: var(--primary-blue);
}

.weHomeDescriptionWhite {
  color: var(--primary-white);
}

/* Team Profile Card */
.teamProfileSectionContent {
  margin-top: 10px;
  text-align: center;
}

.designation {
  max-width: 150px;
}

.teamHeading {
  font-weight: 500;
  color: var(--primary-black);
  margin-top: 2rem;
  justify-content: center;
  align-items: center;
}

.teamProfileCardImage {
  width: 194px;
  height: 213px;
  border-radius: 9px;
  position: relative;
  overflow: hidden;
}

.teamProfileCardImage > img {
  width: inherit;
  height: inherit;
  border-radius: 9px;
  transition: opacity 0.3s; /* Add a smooth transition effect */
  object-fit: cover;
}

.teamImageHover {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  border-radius: 9px;
  background-color: rgba(22, 19, 31, 0.85); /* Semi-transparent background */
  color: var(--primary-white); /* Text color */
  text-align: center;
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.3s; /* Add a smooth transition effect */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.teamImageLinkedin {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  font-size: 2rem;
}

.teamImageLinkedin a {
  color: var(--primary-white);
  text-decoration: none;
}

.teamProfileCardImage:hover .teamImageHover {
  opacity: 0.8;
  display: flex;
}

.teamsCardHeader {
  font-size: 16px;
  font-weight: 700;
}

.teamsDesignation {
  max-width: 200px;
  color: rgba(23, 20, 33, 0.7);
  font-size: 14px;
  font-weight: 400;
}

/* Other Resources Card */

.resourcesContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
  background-color: #212226;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 25px 20px;
}

.resourceImageContainer {
  margin-right: 5rem;
}

.resourceImageContainer > img {
  width: 400px;
}

.resourceContentContainer {
  color: var(--primary-white);
}

.resourceContentContainer > h3 {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.subHeadingResource {
  display: 1.4rem;
  font-style: italic;
  margin-bottom: 1.5rem;
}

.multiLangDownloadContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.multiLangDownloadContainer > select {
  width: 50%;
  outline: none;
  border-radius: 8px;
  padding: 5px;
}

.otherResourcesButton {
  background: linear-gradient(
    180deg,
    var(--app-green) 0%,
    var(--primary-blue) 100%
  );
  border: 0;
  border-radius: 8px;
  padding: 10px 30px;
  font-size: 1rem;
  color: var(--primary-white);
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow 0.5s;
}

.otherResourcesButton:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.otherResourcesButton:disabled,
.otherResourcesButton[disabled] {
  border: 1px solid #999999;
  background: #cccccc;
  color: #666666;
}

@media only screen and (max-width: 1025px) {
  .resourcesContainer {
    flex-direction: column;
    justify-content: center;
  }

  .resourcesContainer > div,
  .multiLangDownloadContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .resourceImageContainer {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .resourceContentContainer > h3 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 600px) {
  .weHome {
    width: 80%;
    /* margin: 0; */
    padding: 2rem 0rem;
  }

  .weHomeImage img {
    height: 50px;
  }

  .weHomeImage {
    margin-bottom: 1rem;
  }

  .weHomeTitle h3 {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
  }

  .visionMissionGoalImage {
    width: 75px;
    height: 75px;
  }

  .visionMissionGoalHeader {
    margin-bottom: 2rem;
    font-size: 2rem;
  }

  .visionMissionGoalParent {
    padding: 2rem 0;
  }

  .glassHalfFullImage {
    width: 100px;
    height: 100px;
  }

  .glassHalfFullHeader {
    margin-bottom: 1rem;
    font-size: 2rem;
  }

  .resourceImageContainer > img {
    width: 300px;
  }
}
