@import url("https://fonts.googleapis.com/css?family=Trirong");

.main{
    height: 100vh;
    width: 100vw;
    display: flex;
    background-color: var(--background-blue);
}
.content{
    font-size: 45px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family:"Trirong";
    color: var(--primary-yellow);
    margin: auto;
}

@media only screen and (max-width:1023px)
{
    .content{
        font-size: 35px;
        padding: 10px;
    }
}