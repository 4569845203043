.storyBookAuthorMainContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.storyBookAuthorContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  gap: 1rem;
  padding: 1.5rem;
}

.storyBookAuthorContainer > img {
  height: 200px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.storyBookAuthorContainer > div {
  width: 60%;
}

.storyBookAuthorName {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--app-green);
}

.storyBookAuthorContent {
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .storyBookAuthorContainer {
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding: 1rem;
  }

  .storyBookAuthorContainer > div {
    width: 100%;
  }

  .storyBookAuthorName {
    font-size: 1.2rem;
  }

  .storyBookAuthorContent {
    font-size: 0.8rem;
  }
}
