.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
}

.logo {
  width: 120px;
  cursor: pointer;
}

.menuIcon {
  display: none;
  cursor: pointer;
  font-size: larger;
  color: var(--primary-white);
  margin-right:10px;
}

.rightNavContainer {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
}

.button {
  background: linear-gradient(180deg, var(--app-green) 0%, var(--primary-blue) 100%);
  border: 0;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 1rem;
  color: var(--primary-white);
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow .5s;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.navLink {
  text-decoration: none;
  color: var(--primary-white);
  font-weight: 500;
  margin-right: 1rem;
}

.dropDownLinkName {
  margin-right: 5px;
}

.dropDownContainer {
  position: relative;
  display: inline-block;
  color: var(--primary-white);
  font-weight: 500;
  margin-right: 1rem;
  transition: 0.5s display ease-in-out;
}

.dropDown {
  display: none;
  position: absolute;
  background-color: var(--primary-white);
  min-width: 200px;
  box-shadow: 5px 16px 16px 8px rgba(0, 0, 0, 0.4);
  z-index: 1;
  border-radius: 5px;
  transition: 0.5s display ease-in-out;
}

.dropDown a {
  color: var(--primary-black);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: inherit;
}

.dropDown a:hover {
  background-color: #ddd;
}

.dropDownContainer:hover .dropDown {
  display: block;
  -webkit-animation: fadeIn 0.75s;
  animation: fadeIn 0.75s;
}

.highLightBorder {
  position: relative;
}

.highLightBorder:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 0;
  border-radius: 1px;
  border-bottom: 2px solid var(--app-green);
  animation: borderAnim 0.5s linear forwards;
}

.drawer{
  padding: 0 20px 0 20px;
  width: 60%;
}

@keyframes borderAnim {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 1023px) {

  .rightNavContainer {
    display: none;
  }

  .menuIcon {
    display: block;
  }
}
