.button {
  background: linear-gradient(180deg, var(--app-green) 0%, var(--primary-blue) 100%);
  border: 0;
  border-radius: 20px;
  padding: 15px 60px;
  font-size: 1.5rem;
  color: var(--primary-white);
  font-weight: 500;
  cursor: pointer;
  transition: box-shadow .5s;
}

.button:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

@media only screen and (max-width: 600px) {
  .button {
    padding: 10px 15px;
    border-radius: 10px;
    font-size: 1rem;
  }
}
