.swiperContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.swiperButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.swiperButtonIcon {
  color: var(--app-green);
  font-size: 3rem;
}

.swiper .swiper-slide {
  height: auto;
}

.swiperContentContainer {
  width: 80%;
}
